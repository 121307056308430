import { DatePicker, Form, Input, Radio } from 'antd';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import styles from "./PassengerFormRow.module.scss";
import moment from "moment";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import Info from "../../assets/images/ic_round-info.svg";

interface Props {
  type: PassengerType;
  passenger: any;
  onChange: any;
  isValidationVisible: boolean;
  index: number;
}

export enum PassengerType {
  ADULT = "ADULT",
  CHILD = "CHILD",
  INFANT = "INF"
}

const PassengerFormRow = forwardRef(({ type, index, passenger, onChange, isValidationVisible }: any, ref) => {

  const { t, i18n } = useTranslation();
  const isAlbanian = i18n.language === 'sq';

  const [dateNotValid, setDateValid] = useState(false);
  const [nameError, setNameError] = useState<string>('');
  const [surnameError, setSurnameError] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');
  const [phoneError, setPhoneError] = useState<string>('');

  // useEffect(() => {
  //   const savedForm = localStorage.getItem(`passengerForm-${index}`);
  //   if (savedForm) {
  //     const savedPassenger = JSON.parse(savedForm);
  //
  //     savedPassenger.email = savedPassenger.email || "";
  //     savedPassenger.phoneNumber = savedPassenger.phoneNumber || "";
  //
  //     onChange(savedPassenger);
  //   } else {
  //     onChange({
  //       ...passenger,
  //       email: "",
  //       phoneNumber: "",
  //     });
  //   }
  //
  //   return () => {
  //     localStorage.removeItem(`passengerForm-${index}`);
  //   };
  // }, []);

  useEffect(() => {
    if (passenger) {
      localStorage.setItem(`passengerForm-${index}`, JSON.stringify(passenger));
    }
  }, [passenger]);

  useEffect(() => {
    setDateValid(disabledDates(passenger.dateOfBirth));
  }, [passenger.dateOfBirth]);

  const handleChange = (name: string, value: any) => {
    let newPassenger = { ...passenger, [name]: value };
    onChange(newPassenger);
    if (name === 'dateOfBirth') {
      setDateValid(disabledDates(value));
    }
  }

  const capitalizeAllLetters = (string: string) => {
    return string.toUpperCase();
  };

  const PassengerTitle: any = {
    [PassengerType.ADULT]: t("app.bookFlight.passengers.passengerRow.adult", "Adult"),
    [PassengerType.CHILD]: t("app.bookFlight.passengers.passengerRow.child", "Child"),
    [PassengerType.INFANT]: t("app.bookFlight.passengers.passengerRow.baby", "Infant")
  }

  const disabledDates = (date: any) => {
    const current = moment(date, "DD/MM/YYYY");

    // The infant must be between 7 days and 1 year old
    const sevenDaysAgo = moment().subtract(7, 'days');
    const oneYearAgo = moment().subtract(1, 'year');

    if (type === PassengerType.INFANT) {
      return current.isAfter(sevenDaysAgo) || current.isBefore(oneYearAgo);
    } else if (type === PassengerType.CHILD) {
      const years = moment().diff(current, "years");
      return years <= 1 || years >= 11;
    }

    return false;
  }


  const validateEmail = (email: string) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (!email.trim() && isValidationVisible) {
      setEmailError(t("app.validations.required", "Required"));
    } else if (!emailRegex.test(email)) {
      setEmailError(t("app.passengerForm.error.email", "Formati i pavlefshëm i email-it."));
    } else {
      setEmailError("");
    }
  }

  const validateName = (name: string) => {
    const nameRegex = /^[A-Za-z\s]+$/;
    if (!name.trim()) {
      setNameError(t("app.validations.required", "Required"));
    } else if (!nameRegex.test(name)) {
      setNameError(t("app.passengerForm.error.alphabet", "Lejohen vetëm shkronjat e alfabetit."));
    } else {
      setNameError('');
    }
  }

  const validateSurname = (surname: string) => {
    const surnameRegex = /^[A-Za-z\s]+$/;
    if (!surname.trim()) {
      setSurnameError(t("app.validations.required", "Required"));
    } else if (!surnameRegex.test(surname)) {
      setSurnameError(t("app.passengerForm.error.alphabet", "Lejohen vetëm shkronjat e alfabetit."));
    } else {
      setSurnameError("");
    }
  }

  const validatePhone = (phone: string) => {
    // const phoneRegex = /^\+\d+$/;
    if (!phone.trim()) {
      setPhoneError(t("app.validations.required", "Required"));
    }
    // else if (!phoneRegex.test(phone)) {
    //   setPhoneError(t("app.passengerForm.error.phone", "Numri i telefonit është i pavlefshëm. Ju lutemi përdorni numra dhe simbolin \"+\"."));
    // }
    else {
      setPhoneError("");
    }
  }

  const defaultPickerValue = () => {
    if (type === PassengerType.CHILD) {
      return moment().subtract(2, "years");
    }
    // else {
    //   return moment();
    // }
  }

  return (
      <div style={{ marginBottom: "3rem" }}>
        <div className={styles.passengerFormRowTitle}>
          {isAlbanian ? (
              <b>{t("app.bookFlight.passengers.passengerRow.passenger", "Passenger Details")} {PassengerTitle[type]}</b>
          ) : (
              <b>{PassengerTitle[type]} {t("app.bookFlight.passengers.passengerRow.passenger", "Passenger Details")}</b>
          )}
        </div>
        <div className={styles.passengerFormRow}>
          <div className={classNames(styles.col, { [styles.hasError]: nameError !== '' || (!passenger.name?.trim() && isValidationVisible) })}>
            <input
                placeholder={t("app.bookFlight.passengers.passengerRow.name.label", "FIRST NAME")}
                type="text"
                id="name"
                value={passenger.name}
                className={styles.passengerFormRowInput}
                onChange={(e) => {
                  const capitalizedValue = capitalizeAllLetters(e.target.value);
                  handleChange("name", capitalizedValue);
                  validateName(capitalizedValue);
                }} />
            {(!passenger.name?.trim() && isValidationVisible) ? <p style={{ marginTop: '8px', color: 'red' }}>{t("app.validations.required", "Required")}</p>
                : <p style={{ marginTop: '8px', color: 'red' }}>{nameError}</p>}
          </div>

          <div className={classNames(styles.col, { [styles.hasError]: surnameError !== '' || (!passenger.surname?.trim() && isValidationVisible) })}>
            <input
                placeholder={t("app.bookFlight.passengers.passengerRow.surname.label", "LAST NAME")}
                type="text"
                id="surnameInput"
                value={passenger.surname}
                className={styles.passengerFormRowInput}
                onChange={(e) => {
                  const capitalizedValue = capitalizeAllLetters(e.target.value);
                  handleChange("surname", capitalizedValue);
                  validateSurname(capitalizedValue);
                }} />
            {(!passenger.surname?.trim() && isValidationVisible) ? <p style={{ marginTop: '8px', color: 'red' }}>{t("app.validations.required", "Required")}</p>
                : <p style={{ marginTop: '8px', color: 'red' }}>{surnameError}</p>}
          </div>

          {type !== PassengerType.ADULT &&
              <div
                  className={classNames(styles.col, {
                    [styles.hasError]: (!passenger.dateOfBirth && isValidationVisible) || dateNotValid,
                  })}
              >
                  <DatePicker
                      className="passengerFormRowDatePicker"
                      defaultPickerValue={defaultPickerValue()}
                      placeholder={t("app.bookFlight.passengers.passengerRow.birthday.label", "BIRTHDAY")}
                      id="birthdayInput"
                      format="DD/MM/YYYY"
                      value={passenger.dateOfBirth ? moment(passenger.dateOfBirth, "DD/MM/YYYY") : null}
                      onChange={(date) => handleChange("dateOfBirth", date?.format("DD/MM/YYYY"))}
                      inputReadOnly
                      disabledDate={(currentDate) => {
                        // Disable dates after today's date
                        return currentDate && currentDate.isAfter(moment(), 'day');
                      }}
                  />
                {(!passenger.dateOfBirth && isValidationVisible) && (
                    <p style={{marginTop: '8px', color: 'red'}}>
                      {t("app.validations.required", "Required")}
                    </p>
                )}
                {(dateNotValid && passenger.dateOfBirth && !(!passenger.dateOfBirth && isValidationVisible)) && (
                    <span className={styles.validationMessage}>
            {t("app.passengerForm.dateNotValid", "Data nuk është valide")}
        </span>
                )}
              </div>
          }

          {type === PassengerType.ADULT &&
              <div
                  className={classNames(styles.radioButtons, {[styles.hasError]: !passenger.gender?.trim() && isValidationVisible})}>
                  <Radio.Group value={passenger.gender} onChange={(e) => handleChange("gender", e.target.value)}>
                      <Radio value="FEMALE">{t("app.bookFlight.passengers.passengerRow.female", "Female")}</Radio>
                      <Radio value="MALE">{t("app.bookFlight.passengers.passengerRow.male", "Male")}</Radio>
                  </Radio.Group>
                {(!passenger.gender?.trim() && isValidationVisible) &&
                    <p style={{marginTop: '8px', color: 'red'}}>{t("app.validations.required", "Required")}</p>}
              </div>
          }

          {index === 0 && (
              <div>
                <div className={styles.passengerFormRowInputEmail}>
                  <div
                      className={classNames(styles.col, {[styles.hasError]: emailError !== '' || (!passenger.email?.trim() && isValidationVisible)})}>
                    <input
                        placeholder={t("app.contactPerson.email", "EMAIL")}
                        value={passenger.email}
                        id="email"
                        type="email"
                        className={styles.passengerFormRowInput}
                        onChange={(e) => {
                          handleChange("email", e.target.value);
                          validateEmail(e.target.value);
                        }} />
                    {(!passenger.email?.trim() && isValidationVisible) ? <p style={{ marginTop: '8px', color: 'red' }}>{t("app.validations.required", "Required")}</p>
                        : <p style={{ marginTop: '8px', color: 'red' }}>{emailError}</p>}
                  </div>
                  <div className={classNames(styles.col, { [styles.hasError]: phoneError !== '' || (!passenger.phoneNumber?.trim() && isValidationVisible) })}
                  style={{marginRight: '0px'}}>
                    <input
                        placeholder={t("app.contactPerson.phoneNumber", "PHONE")}
                        id="phone"
                        type="tel"
                        value={passenger.phoneNumber}
                        className={styles.passengerFormRowInput}
                        onChange={(e) => {
                          handleChange("phoneNumber", e.target.value);
                          validatePhone(e.target.value);
                        }} />
                    {(!passenger.phoneNumber?.trim() && isValidationVisible) ? <p style={{ marginTop: '8px', color: 'red' }}>{t("app.validations.required", "Required")}</p>
                        : <p style={{ marginTop: '8px', color: 'red' }}>{phoneError}</p>}
                  </div>
                </div>
                <div className={styles.infoMessage}>
                  <img src={Info} width={22} alt="Info"/>
                  <p>
                    {t("app.passenger.information", "Ju lutemi sigurohuni që numri juaj i telefonit dhe adresa juaj e email-it janë të saktë nëse kemi nevojë të ju informojmë për ndonjë ndryshim në fluturimin tuaj.")}
                  </p>
                </div>
              </div>
          )}
        </div>
      </div>
  )
});

export default PassengerFormRow;